import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ShareChat from './components/ShareChat';

const sharechat = (props,{ t }) => (
  <Container  >
    <ShareChat  {...props} />
  </Container>
);

sharechat.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(sharechat);
