import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PublicAi from './components/PublicAi';

const publicai = (props,{ t }) => (
  <Container  >
    <PublicAi  {...props} />
  </Container>
);

publicai.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(publicai);
