import React, { PureComponent, useEffect, useState } from 'react';
import { Button, CardBody, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link,Navigate } from 'react-router-dom';


import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
// import {settingsAction} from '../../../../redux/actions/settingsAction'
import { settings } from '../../../redux/actions/settingsAction'
import PropTypes from 'prop-types';

import renderCheckBoxField from '../../../shared/components/form/CheckBox';

// Validate input
// import validate from '../../Validations/validate';
import validate from '../../Validations/validate';

// Input field
import DefaultInput from '../../../shared/components/form/DefaultInput';

import DoInputField from '../../Form/Fields/DoInputField';
import DoPasswordField from '../../Form/Fields/DoPasswordField';


// Config
import configMessage from '../../../config/configMessages';
import fetchRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import config from '../../../config/config';
import GoogleLogin from 'react-google-login'

// Toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import jwt_decode from "jwt-decode"
import ReCAPTCHA from "react-google-recaptcha";
import {
  browserName,
  osName, osVersion, deviceType
} from "react-device-detect";
// Loader
import Loader from '../../App/Loader';
import { useForm, Controller } from 'react-hook-form';
import PasswordField from '@/shared/components/form/password';

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const publicIp = require('public-ip');


let newpassval = value => (value ? config.passwordRegex.test(value) ? undefined : 'Not a secure paswword, hint:User1234$' : configMessage.fillField)
const newpassvalLength = value => (value ? value.length >= 8 ? undefined : 'Password length should be atleast 8' : configMessage.fillField)

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)
const normalizePhone = (value) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}
const Register = (props) => {

  const [deviceInfo, setDeviceInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState(config.displayRecaptcha ? true : false);
  const [loginRememberInfo, setLoginRememberInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRobot, setIsRobot] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isNotChecked, setIsNotChecked] = useState(false);
  const [remember_me, setRemember_me] = useState(false);
  const [loginRole, setLoginRole] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [settingsData, setSettingsData] = useState({});
  const [isGoogleLoginSuccess, setIsGoogleLoginSuccess] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [displayDetailsModal, setDisplayDetailsModal] = useState(false);
  const formFields = [{"name":"firstName","placeholder":"First Name","label":"First Name","type":"text"},{"name":"email","placeholder":"Email","label":"Email","type":"email"},{"name":"phone","placeholder":"Phone","label":"Phone","type":"text"}]
  // [{ "name": "name", "placeholder": "Name", "label": "Name" }, { "name": "email", "placeholder": "Email", "label": "Email" }, { "name": "phone", "placeholder": "Phone", "label": "Phone" }];

  useEffect( () => {
    getSettingsData();
    setDeviceDetails();
  },[]);

  const setDeviceDetails = async () => {      
    await setDeviceInfo({
      // ipAddress:ipAddr,
      browserName:browserName,
      osName:osName,
      osVersion:osVersion,
      deviceType:deviceType
    });
    // let ipAddr = await publicIp.v4();  
  }

  const schema = yup.object().shape({
    firstName: yup.string().required(),
    phone: yup
    .string()
    .required('Phone Number is Required')
    .matches(/^\d+$/, 'Phone number must contain only digits')
    .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
  ,
    email: yup.string().email().required(),
    password: yup.string().min(8).max(32).required(),
    confirmPassword: yup.string().min(8).max(32).required(),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });



  const submit = (values) => {
    values.status = "Active"
    values.role = "Admin";
    values.deviceInfo = deviceInfo;
    return fetchRequest('POST', "employees/register", values)
      .then(async (response) => {
        if (response && response.respCode && response.respCode == 204) {
          setIsLoginSuccess(true);
          setIsNotChecked(false);
          let userDetails = response.details
          if (response.accessToken) {
            let tokenInfo = {
              accessToken: response.accessToken,
              refreshToken: response.refreshToken,
            };
            userDetails = { ...userDetails, ...tokenInfo };
            // save user credentials in storage
            localStorage.setItem('loginCredentials', JSON.stringify(userDetails));

          }
          showToasterMessage(response.respMessage, 'success');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }

      })
  }

  const onChange = (value) => {
    if (config.displayRecaptcha) {
      setCaptchaValue(value ? value : null);
      setIsLoginDisabled(value ? false : true);
    }
  }

  const getSettingsData = () => {
    let settings;
    fetchRequest('GET', `settings`).then(async (response) => {
      if (response && response.respCode) {
        settings = response.settings[0]
        settings["GoogleClientId"] = settings["Admin"].GoogleClientId;
        settings["GooglesecretKey"] = settings["Admin"].GooglesecretKey;
        setSettingsData(settings);
      }

    })
    return settingsData;
  }

  const loadGoogleComponent = (self) => {
    function handleCredentialResponse(response) {
      var data = jwt_decode(response.credential);
      if (data.email && data.name && config.displayGoogleLogin) {
        return fetchRequest('POST', 'auth/socialLogin', { name: data.name, email: data.email, role: "Admin", entityType: config.entityType, deviceInfo: self.state.deviceInfo })
          .then(async (response) => {
            if (response && response.respCode && response.respCode == 200) {
              self.setState({
                isLoading: true
              });
              let userDetails = response.details;
              if (userDetails && userDetails._id) {
                if (response.accessToken) {
                  let tokenInfo = {
                    accessToken: response.accessToken,
                    refreshToken: response.refreshToken,
                    tokenExpires: response.tokenExpires,
                  };
                  userDetails = { ...userDetails, ...tokenInfo };

                  // save user credentials in storage
                  localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
                }
                if (userDetails && userDetails.rolePermissions) {
                  let permissions = userDetails.rolePermissions;
                  // save user permissions in storage
                  localStorage.setItem('rolePermissions', JSON.stringify(permissions));
                }
              }

              showToasterMessage(response.respMessage, 'success');

              if (userDetails.firstTimeLogin) {
                self.setState({
                  displayDetailsModal: true,
                  isLoading: false,
                  userDetails: userDetails
                })
                if (self.props.load) {
                  setTimeout(() => {
                    self.props.load(self.state.userDetails)
                  }, 300);
                }

              } else {
                self.setState({
                  isLoginSuccess: true,
                  isGoogleLoginSuccess: true,
                })
              }
            } else if (response && response.errorMessage) {
              setTimeout(() => {
                self.setState({
                  isLoginDisabled: false,
                  isLoading: false
                })
              }, 250);
              showToasterMessage(response.errorMessage, 'error');
            }
            // settingsAction
          }).catch((err) => {
            self.setState({
              isLoginDisabled: false,
              isLoading: false
            });
          });
      }
    }
    if (config.displayGoogleLogin) {
      // window.onload = function () {
      if (self.state.settingsData && self.state.settingsData.GoogleClientId) {
        let GoogleClientId = self.state.settingsData && self.state.settingsData.GoogleClientId ? self.state.settingsData.GoogleClientId : null
        /* global google */
        google.accounts.id.initialize({
          client_id: GoogleClientId,
          callback: handleCredentialResponse
        });
        google.accounts.id.renderButton(
          document.getElementById("loginButton"),
          { theme: "outline", size: "large", width: document.getElementById('googleButton').clientWidth }  // customization attributes
        );
        // google.accounts.id.prompt(); // also display the One Tap dialog
      }
      // }
    }
    return true
  }

  const cancelReset = async type => {
    setIsLoginSuccess(false);
    setIsGoogleLoginSuccess(false);
    setDisplayDetailsModal(false);
    localStorage.removeItem("rolePermissions");
    localStorage.removeItem("loginCredentials");
    reset();
  }

  const handleModalSubmit = async formValues => {
    console.log('form values', formValues);
    if (formValues) {
      delete formValues.email;
      delete formValues.password;
      formValues["firstTimeLogin"] = false
      let Url;
      if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        Url = `${apiCalls["Employees"]}/${user._id}`;
      }
      return fetchRequest('PUT', Url, formValues)
        .then(async (response) => {
          if (response && response.respCode && response.respCode === 205) {
            showToasterMessage(response.respMessage, 'success');
            setIsLoginSuccess(false);
            setIsGoogleLoginSuccess(true);
            setDisplayDetailsModal(false);            
          } else if (response && response.errorMessage) {
            setIsLoginSuccess(false);
            setIsGoogleLoginSuccess(false);
            setDisplayDetailsModal(false);
            showToasterMessage(response.errorMessage, 'error');
          }
        }).catch((err) => {
          setIsLoginSuccess(false);
          setIsGoogleLoginSuccess(false);
          setDisplayDetailsModal(false);
          return err;
        });

    } else {
      setIsLoginSuccess(false);
      setIsGoogleLoginSuccess(false);
      setDisplayDetailsModal(false);
      return;
    }

  }

  return (
    <span>
      {displayDetailsModal ?
        <Modal
          isOpen={displayDetailsModal}
          toggle={cancelReset}
          centered
          className={`modal-dialog modal-dialog-centered modal-dialog--primary  modal-dialog--header`}
        >
          <ModalHeader className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={cancelReset}
            />
            <p className="bold-text  modal__title"> {"Details"} </p>
          </ModalHeader>
          <ModalBody className="p-2">
            <Loader loader={isLoading} />

            <form className="form" onSubmit={handleSubmit(handleModalSubmit)}>
              <div className="row mx-1 mt-3 " style={{ width: '100%' }}>

                {
                 formFields && formFields.length && formFields.length > 0 ?
                    formFields.map((item, index) => {
                      return (
                        <div className="col-sm-12 col-md-6">
                          <div className="form__form-group ">
                            <span className="form__form-group-label">{item.label}</span>
                            <div className="form__form-group-field">
                              <input
                                  name={item.name}
                                  type="text"
                                  placeholder={item.placeholder}
                                  {...register(`${item.name}`)}
                                />
                            </div>
                          </div>
                        </div>

                      )
                    }) : null

                }
                <div className="col-sm-12 text-center pt-3">
                  <div>
                    <Button
                      outline
                      color="primary"
                      type="buttom"
                      onClick={cancelReset}
                    >
                      Cancel
                    </Button>

                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
        :
        <div>
         < form className="form" autocomplete="off" onSubmit={handleSubmit(submit)}>
            <Loader loader={isLoading} />
            <div className='w-100'>
              {
                formFields && formFields.length && formFields.length > 0 ?
                  formFields.map((item, index) => {
                    return (
                      <div className="form__form-group pb-2">
                        <div>
  
                          <Controller
                            name={item.name}
                            control={control}
                            render={({ field, fieldState }) => (
                              <DoInputField
                                input={field}
                                id={field.id}
                                name={field.name}
                                field={field}
                                type="text"                      
                                fieldState={fieldState}
                                errors={errors}
                                placeholder={item.placeholder}
                              />)}
                          />

                        </div>
                        
                      </div>
                    )
                  }) : null

              }

              <div className="form__form-group pb-2">
                <div >
          
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoPasswordField
                        input={field}                      
                        name={field.name}
                        field={field}
                        fieldState={fieldState}
                        errors={errors}
                        placeholder="Password"
                      />)}
                  />
                </div>
    
              </div>
              <div className="form__form-group pb-2">
                <div >
            
                  <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoPasswordField
                        input={field}                      
                        name={field.name}
                        field={field}
                        fieldState={fieldState}
                        errors={errors}
                        placeholder="Confirm Password"
                      />)}
                  />
                </div>
              </div>
            </div>

            
            <div className="w-100 mt-2">
              {config.displayRecaptcha && settingsData && settingsData.GooglesecretKey ?
                < ReCAPTCHA
                  sitekey={settingsData && settingsData.GooglesecretKey}
                  // sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
                  onChange={onChange}
                  type="image"
                  className='recap'
                />
                : null}
            </div>
            <div className="account__btns" style={{ margin: 'auto', width: '100%' }}>
              <Button
                className=" mt-2 mb-0"
                color="primary"
                disabled={isLoginDisabled}
                style={{ width: '100%' }}
              >
                Register
              </Button>
            </div>
            {config.displayGoogleLogin ?
              <div className='line mt-4'>
                <div className='text'>
                  or
                </div>
              </div>
              : null
            }
            <div className='d-flex flex-column w-100 pb-3'>
              {config.displayGoogleLogin ?
                <div id="googleButton">
                  <div id="loginButton" className="strike w-100"></div>
                </div>
                : null
              }
            </div>
            <div className='py-2'>
              <p>
                Back to <Link to="/log_in">Login</Link>
              </p>
            </div>
            {isLoginSuccess ? <Navigate to="/log_in" state={{'message' : configMessage.verifyEmailMessage}}/> : null}
            {isGoogleLoginSuccess ? <Navigate to={config.defaultRoute} /> : null}
          </form>
        </div>
      }
    </span >
  );
}

export default Register;
