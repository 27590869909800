import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useSpeechSynthesis } from 'react-speech-kit';
import { stripHtmlTags } from '../../../utils/utils';
import { useLocation } from 'react-router';

const ReadAloud = ({ text , onActivate, isActive}) => {

  const { speak,cancel, speaking } = useSpeechSynthesis();
  let parsedText =  stripHtmlTags(text);
  const location = useLocation()

  useEffect(() => {
    if(speaking) {
        cancel()
    }
  },[location])

  useEffect(() => {
    const handleBeforeUnload = () => {
      speechSynthesis.cancel();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      speechSynthesis.cancel();
    };
  }, []);


  const handleReadAloud = () => {
    if(onActivate) {
        onActivate();
    }
    cancel();
    speak({ text : parsedText});
  };

  const onStopReadAloud = () => {
    cancel()
    onActivate('cancel')
  }

  return (
    <div>
      {isActive ? <FontAwesomeIcon
        icon='volume-mute'
        className='pl-1' size='lg'
        data-toggle="tool-tip" title="Stop Speaking"
        style={{
          color :'#12225f'
        }}
        onClick={onStopReadAloud}
          />
          :
          <FontAwesomeIcon
        icon='volume-up'
        className='pl-1' size='lg'
        data-toggle="tool-tip" title="Read Aloud"
        style={{
          color :'#12225f'
        }}
        onClick={handleReadAloud}
          />}
    </div>
  );
};

export default ReadAloud;
