import React, { useState, useEffect } from 'react';
import { Button } from "reactstrap";
import { Navigate } from 'react-router-dom';
import DoInputField from '../../../Form/Fields/DoInputField';
import { Checkbox } from 'primereact/checkbox';
import { Controller, useForm } from 'react-hook-form';
import fetchMethodRequest from '../../../../config/service';
import config from '../../../../config/config';
import 'react-toastify/dist/ReactToastify.css';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';

// Define Yup schema for validation
const schema = yup.object().shape({
    otp: yup.string().required('Otp is required')
        .min(6, 'Otp must be at least 6 characters')
        .max(6, 'Otp must be at most 6 characters')
});

const TwoStepVerification = (props) => {

    const [projectName, setProjectName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [smsText, setSmsText] = useState('SMS/Text');
    const [emailText, setEmailText] = useState('Email');
    const [selectionType, setSelectionType] = useState('');
    const [showTimerSMS, setShowTimerSMS] = useState('');
    const [showTimerEmail, setShowTimerEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [isLoginDisabled, setIsLoginDisabled] = useState(false);
    const [isLoginSuccess, setIsLoginSuccess] = useState(false);
    const [isRemember, setIsRemember] = useState(false)

    let loggedEmployee = JSON.parse(localStorage.getItem('employee'));

    useEffect(() => {
        setProjectName(config.appName);
        setEmail(loggedEmployee.email);
        if (loggedEmployee && loggedEmployee.phone) {
            setPhoneNumber(loggedEmployee.phone);
        }
    }, []);

    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const sendRequestMail = async (type) => {
        setSelectionType(type)
        let obj = {
            ...loggedEmployee,
            type: type,
            entityType: config.entityType
        };
        if (selectionType !== 'Authenticator') {
            startTimer(type);
        }
        return fetchMethodRequest('POST', 'auth/sendotp', obj)
            .then(async (response) => {
                if (response && response.respMessage) {
                    showToasterMessage(response.respMessage, 'success');
                    if (type === 'sms') {
                        setSmsText('Resend-SMS/Text');
                    } else if (type === 'email') {
                        setEmailText('Resend-Email');
                    } else if (type === "Authenticator") {

                    }
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                } else {
                    showToasterMessage('otp is not sent');
                }
            }).catch((err) => {
                setIsLoginDisabled(false);
                setIsLoading(false);
            });
    }

    const submitForm = (values) => {
        sendOtpConfirmation(values);
    }

    const sendOtpConfirmation = async (formValues) => {
        if (formValues.otp) {
            const obj = {
                otp: formValues.otp,
                email: loggedEmployee.email,
                isRemember: isRemember,
                entityType: config.entityType
            };
            let endPoint = 'auth/verifyotp';
            if (selectionType === 'Authenticator' || !selectionType) {
                endPoint = 'auth/verifyAuthenticatorOTP';
            }

            return fetchMethodRequest('POST', endPoint, obj)
                .then(async (response) => {
                    if (response && response.respMessage) {
                        if (response.accessToken && response.details) {
                            const { details, accessToken } = response;
                            const employeeDetails = {
                                ...details,
                                accessToken: accessToken
                            };

                            // Setting token and logged in employee details to local storage
                            localStorage.setItem('loginCredentials', JSON.stringify(employeeDetails));

                            // Set role permissions in local storage
                            if (employeeDetails.rolePermissions) {
                                localStorage.setItem('rolePermissions', JSON.stringify(employeeDetails.rolePermissions));
                            } else {
                                setRolesPermission(employeeDetails.role);
                            }
                            showToasterMessage(response.respMessage, 'success');
                            setTimeout(() => {
                                setIsLoginSuccess(true)
                            }, 500);
                        }
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                        setOtpStatus(true)
                    }
                }).catch((err) => {
                    showToasterMessage('An error occurred while sending OTP');
                });
        } else {
            setOtpStatus(true);
        }
    }


    const startTimer = (type) => {
        let min = 1;
        let sec = 59;
        const updateTimer = () => {
            if (type === 'sms') {
                let timings = `${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`;
                setShowTimerSMS(timings);
            } else {
                let timings = `${min < 10 ? '0' : ''}${min}: ${sec < 10 ? '0' : ''}${sec}`
                setShowTimerEmail(timings);
            }
            if (min === 0 && sec === 0) {
                clearInterval(interval);
                type === 'sms' ? setShowTimerSMS('') : setShowTimerEmail('');
            } else {
                sec = sec === 0 ? 59 : sec - 1;
                min = sec === 59 ? min - 1 : min;
            }
        };

        updateTimer();
        const interval = setInterval(updateTimer, 1000);
    }


    return (
        <div className='login-container' >
            <div className='card'>
                <div className='card-body'>

                    <div className="container-fluid">
                        <h5 className="text-center header-message"><b>Two-Factor Authentication</b></h5>
                        <p><b>{projectName}</b> needs two-factor authentication, please select method of delivery:</p>
                    </div>

                    <div className="container-fluid my-3">
                        <div className="container d-flex justify-content-around">
                            {phoneNumber &&
                            <div className="scale" style={{ maxWidth: '100%' }}>
                                <Button style={{ width: '100%', marginBottom: '0px' }} color="primary" type='button'
                                    onClick={() => sendRequestMail('sms')} disabled={showTimerSMS}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <i className="pi pi-phone" style={{ marginRight: '5px' }}></i>
                                        <span style={{ fontSize: '12px' }}>{smsText}</span>
                                    </div>
                                </Button>
                                {showTimerSMS && <small className="text-danger">Resend SMS in {showTimerSMS}</small>}
                            </div>
                            }

                            <div className="scale" style={{ maxWidth: '100%' }}>
                                <Button style={{ width: '100%', marginBottom: '0px' }} color="primary" type='button'
                                    onClick={() => sendRequestMail('email')} disabled={showTimerEmail}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <i className="pi pi-envelope" style={{ marginRight: '5px' }}></i>
                                        <span style={{ fontSize: '12px' }}>{emailText}</span>
                                    </div>
                                </Button>
                                {showTimerEmail && <small className="text-danger">Resend EMail in {showTimerEmail}</small>}
                            </div>

                            <div className="scale" style={{ width: '100%' }}>
                                <Button className="btn btn-main" style={{ width: '100%' }} color="primary" type='button'
                                    onClick={() => setSelectionType('Authenticator')}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <i className="pi pi-envelope" style={{ marginRight: '5px' }}></i>
                                        <span style={{ fontSize: '12px' }}>Authenticator</span>
                                    </div>
                                </Button>
                            </div>
                        </div >
                    </div >

                    <div className="w-100">
                        <form className="form" onSubmit={handleSubmit(submitForm)}>

                            <div className="mb-3">
                                {selectionType === 'sms' && (
                                    <div className="text-info">
                                        OTP is sent to registered mobile number {phoneNumber?.slice(0, 3) + 'xxxx' + phoneNumber?.slice(phoneNumber?.length - 3)}.
                                    </div>
                                )}
                                {selectionType === 'email' && (
                                    <div className="text-info">
                                        OTP is sent to registered email address {email}.
                                    </div>
                                )}
                                {selectionType === 'Authenticator' && (
                                    <div className="text-info">
                                        Open Authenticator app in your device and enter the 6-digit code.
                                    </div>
                                )}
                            </div>

                            <div className="mb-3">
                                <Controller
                                    name="otp"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            input={field}
                                            id={field.id}
                                            field={field}
                                            name={field.name}
                                            type="text"
                                            fieldState={fieldState}
                                            errors={errors}
                                            placeholder="otp"
                                            style={{ width: '22.5rem' }}
                                        />
                                    )}
                                />
                            </div >

                            <div className="mb-3">
                                <Controller
                                    name="remember_me"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Checkbox inputId={field.name} checked={isRemember} onChange={(e) => setIsRemember(!isRemember)} />
                                            <label htmlFor={field.name} className='ms-1'>Remember me for 5 days.</label>
                                        </>
                                    )}
                                />
                            </div>

                            <div className="account__btns" style={{ width: '100%' }}>
                                <Button className="ml-auto" style={{ width: '100%' }}
                                    color="primary" type='submit'>
                                    Sign in
                                </Button>
                            </div>

                            {selectionType === 'Authenticator' && (
                                <div className="text-left" >
                                    <a href={"/authenticator"} onClick={() => sendRequestMail('email')}>
                                        Doesn't have an Authenticator ?
                                    </a>
                                </div>
                            )}

                            {isLoginSuccess ? <Navigate to="/homes" /> : null}
                        </form>
                    </div>
                </div >
            </div >
        </div >
    )
}
export default TwoStepVerification;