import React, { useEffect, useState } from 'react';
import { Navigate, NavLink, useSearchParams } from "react-router-dom";
import config from '../../../../config/config';
import fetchMethodRequest from '../../../../config/service';
import { HashLoader } from 'react-spinners';
import apiCalls from '../../../../config/apiCalls';
import configMessages from '../../../../config/configMessages';
import { Button } from 'primereact/button';

const VerifyEmail = () => {
    const [fadeIn, setFadeIn] = useState(false);

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    let [emailHeader , setEmailHeader] = useState('')
    let [emailDescription, setEmailDescription] = useState('')

    useEffect(() => {
        if(token) {
            let url = `${apiCalls.verifyEmail}?token=${token}`
            fetchMethodRequest('GET',url, '').then((res) => {
                if(res && res.respMessage) {
                    setEmailHeader(configMessages.verifyEmailHeaderSucceess)
                    setEmailDescription(configMessages.verifyEmailDescriptionSuccess)
                } else if (res && res.errorMessage) {
                    setEmailHeader(configMessages.verifyEmailHeaderFailed)
                    setEmailDescription(configMessages.verifyEmailDescriptionFailed)
                }
            })
        }
        setTimeout(() => setFadeIn(true), 200); // Add a delay before the animation starts
    }, []);

    return (
        <div style={styles.container}>
            <div style={{ ...styles.card, opacity: fadeIn ? 1 : 0, transform: fadeIn ? 'translateY(0)' : 'translateY(20px)' }}>
                {emailHeader ?<>
                    <h1 style={{...styles.title, color : emailHeader === configMessages.verifyEmailHeaderSucceess ? '#4CAF50' : '#FF0000'}}>{emailHeader}</h1>
                    <p style={styles.message}>{emailDescription}</p>
                </> : <HashLoader/>}
                {emailHeader === configMessages.verifyEmailHeaderSucceess &&
                    <Button className='mt-3' size='sm'>
                        <NavLink to={'/'} style={{color : '#fff'}} >Go To Login</NavLink>
                    </Button>
                }
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '98vh',
        backgroundColor: '#f0f2f5',
        transition: 'background-color 0.5s ease-in-out',
    },
    card: {
        backgroundColor: '#fff',
        padding: '40px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        transition: 'opacity 1s ease-in-out, transform 1s ease-in-out', // Animation styles
    },
    title: {
        fontSize: '24px',
        marginBottom: '16px',
        color: '#4CAF50', // Green for success
    },
    message: {
        fontSize: '18px',
        color: '#333',
    },
};

export default VerifyEmail;
