import React, {useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import configImages from '../../../../config/configImages';
import fetchMethodRequest from '../../../../config/service';
import {  useLocation, useParams } from 'react-router-dom';
import ReadAloud from '../../CommonComponents/ReadAloud';
import CopyText from '../../CommonComponents/CopyText';
import {marked} from 'marked';
import { Card, CardBody } from 'reactstrap';
import config from '../../../../config/config';

const ShareChat = () => {
  const [messages, setMessages] = useState([]);
  let messagesRef = useRef([])
  const chatHistoryRef = useRef(null);
  const noProfileImage = configImages.defaultImg;
  const {chatId} = useParams();
  let filterRef = useRef({"page":1,"limit":20,"sortfield":"created","direction" : "desc","criteria":[{"key":"chatId","value": chatId,"type":"eq"}]})
  const [totalCount,setTotalCount] = useState(0)
  let newMessageRef = useRef();
  let previousScrollHeightRef = useRef();
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  const [activeSpeechMessageId ,  setActiveSpeechMessageId] = useState('');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');
  const [chat , setChat] = useState([]);
  const [files , setFiles] = useState([])

  useEffect(() => {
    if(chatId && !type){
      fetchMethodRequest('GET', `chats/${chatId}`).then((res) => {
        if(res && res.details) {
          setChat(res.details);
        }
      })
    }
    let obj = Object.assign(filterRef.current,{"page" : 1,"criteria":[{"key":"chatId","value": chatId,"type":"eq"}]});
    filterRef.current = obj;
    setMessages([]);
    setIsInitialLoaded(false)
    messagesRef.current = [];
    if(chat && chat.files) {
      setFiles(chat.files);
    }
    setTimeout(() => {
      insertMessages(obj)
    }, 200);
    setActiveSpeechMessageId(null);
  },[chatId])

  useEffect(() => {
    if (chatHistoryRef.current && (filterRef.current?.page === 1 || newMessageRef.current)) {
      newMessageRef.current = false
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    } else {
      if(chatHistoryRef?.current){
        chatHistoryRef.current.scrollTop = chatHistoryRef?.current.scrollHeight - previousScrollHeightRef.current;
      }

    }
  }, [messages]);

  useEffect(() => {
    if(chat?.files?.length > 0) {
      setFiles(chat.files)
    }
  }, [chat])


  function insertMessages(defaultFilter) {
    let finalFilter = defaultFilter ? defaultFilter : filterRef.current;
    if(!chatId) return;
    if(type && type === 'message') {
      finalFilter.criteria = [{"key":"_id","value": chatId,"type":"eq"}]
    }
    fetchMethodRequest('GET',`messages?filter=${JSON.stringify(finalFilter)}`).then(async (resp) => {
      if (resp && resp.messages?.length > 0) {
        if(resp.pagination?.page) {
          if(resp.pagination?.page === 1) setIsInitialLoaded(true);
          let obj = Object.assign(finalFilter, {"page" : resp.pagination?.page});
          filterRef.current = obj
        }
        if(resp.pagination?.totalCount) {
          setTotalCount(resp.pagination?.totalCount)
        }
        if(finalFilter.page  === 1) {
          messagesRef.current = resp.messages?.reverse()
          setMessages(messagesRef.current);
        } else {
          previousScrollHeightRef.current = chatHistoryRef.current?.scrollHeight;
          messagesRef.current = [...resp.messages?.reverse(),...messagesRef.current]
          setMessages(messagesRef.current);
        }
      }
    })
  };

  const onScrollTop = (e) => {
    const bottom = e.target.scrollTop == 0;
    if(bottom  && messages.length < totalCount && isInitialLoaded) {
      let currentFilter = JSON.parse(JSON.stringify(filterRef.current))
      currentFilter.page = currentFilter.page + 1;
      insertMessages(currentFilter)

    }
  }

  const handleActiveSpeectMessage = (messageId) => {
    setActiveSpeechMessageId(messageId)
  }

  return (
    <>
      <div className='summary-search-containar' style={{ backgroundColor : '#fff'}}>
    <div className='col-sm-12 d-flex align-items-start'>
      <div className='col-sm-9 justify-content-center align-items-center chat-bot-main'>
        <div className="chatbot-container">
          {
            chatId || messages?.length > 0? 
            <> 
            <div className="chatbot-messages" ref={chatHistoryRef} onScroll={onScrollTop}>
              <div className='d-flex justify-content-end align-items-center' key={'file'}>
                {/* No need of sharing the files for now  */}
                {false && files?.length > 0 &&
                  <>
                    <div className={'message user text-align-right'}>
                      {files.map(file => {
                        return <a href={config.imgUrl + file?.path}>
                          <Card className='folder-box p-0 mx-2 w-auto'>
                                  <CardBody className='tableCardBody text-align-left p-1 d-flex justify-content-between align-items-center'>
                                  <FontAwesomeIcon
                                    icon={ file.type === 'docx' ? 'file-word' : 'file-pdf'}
                                    className='iconsize'
                                    size='1x'
                                    style={{ color: file.type && file.type === 'pdf' ? 'brown' : (file.type === 'docx') ? '#1292ee' : 'inherit' , backgroundColor : 'transparent', fontSize : '2rem'}}
                                  data-toggle='tool-tip'
                                  title='Settings'
                                />
                                <span style={{color : 'black'}}>{file.name}</span>
                                </CardBody>
                          </Card>
                        </a>
                      })}
                    </div>
                  <div className='icon-style mx-2'>
                          <img src={noProfileImage} alt="" />
                  </div>
                  </>
                }
                </div> 
              {messages.map((message, index) => (
                <>
                  <div className='d-flex justify-content-end align-items-center' key={'question'+index}>
                    <div className={'message user'}>{message?.question}</div>
                    <div className='icon-style mx-2'>
                      <img src={noProfileImage} alt="" />
                    </div>
                  </div> 
                  <div className='d-flex justify-content-start align-items-start' key={'answer'+index}>
                    <div className='icon-style mx-2 mt-1'>
                      <FontAwesomeIcon
                        className='genderIconAlignment'
                        color='white'
                        icon='comment-dots'
                        data-toggle="tool-tip"
                        title='Send'
                      />
                    </div>
                    {
                      
                      message?.answer && message?.answer !== 'loader' ?
                        <div className='message bot'>
                          <div dangerouslySetInnerHTML={{ __html: message?.type !== 'error' ? marked(message.answer) : message.answer }} ></div>
                          <div className='d-flex justify-content-start align-items-center gap-1 mt-1 message-options-container' style={index === messages.length -1 ? {visibility : 'visible'} : {}}>
                          <span className='message-options'>
                            <ReadAloud text={message.answer} onActivate={(msg) => handleActiveSpeectMessage(msg === 'cancel' ? null : message._id)} isActive={activeSpeechMessageId == message._id}/>
                          </span>
                          <span className='message-options'>
                            <CopyText text={message.answer}/>
                          </span > 
                          <span className='message-options'>
                            <CopyText text={message.answer} copyType={'TextCopy'} iconName={'copy'}/>
                          </span > 
                          </div>
                        </div>
                    : null
                    }
                    
                  </div>
                  </>
              ))}
            </div>
           
          </> :
          <div className='new-chat'>
            <img src={configImages.dosystemsImg} alt="" />
          </div>
          }
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default ShareChat;
