import React, {useState} from 'react';

import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { Link,Navigate, useNavigate } from 'react-router-dom';

import fetch from '../../../../config/service';
import config from '../../../../config/config';

import { useForm, Controller } from 'react-hook-form';

// show message 
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

// Loader
import Loader from '../../../App/Loader';

// Input

import DoPasswordField from '../../../Form/Fields/DoPasswordField';
import { getPasswordRegex } from '../../../Form/DoValidations';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";





const ChangePasswordForm = () => {

 
  const [isLoading,setIsLoading] = useState(false);
  const [redirect,setRedirect] = useState(false);
  const navigate = useNavigate()
 
  const schema = yup.object().shape({  
    currentPassword: yup.string().required(),
    newPassword: yup.string().required().matches(getPasswordRegex(),"Password must contain at least 8 characters, one uppercase, one number and one special case character"),
    confirmPassword: yup.string().required().test('passwords-match', 'New Passwords and confirm password must match', function(value){
      return this.parent.newPassword === value
    }),
    
  });

  let {
    handleSubmit,   
    reset,   
    formState: { errors },
    control,
} = useForm({
    resolver: yupResolver(schema),
});

 
  // handle login user data
  const submit = (values) => {
   
    setIsLoading(true);
    let userData = localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
   

    let userBody = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
      entityType: config.entityType,

    };
    return fetch('POST', `auth/changePassword `, userBody)
      .then((response) => {
        if (response && response.respCode && response.respCode === 200) {
          // display message          
          showToasterMessage(response.respMessage, 'success');
          navigate(-1);
        } else if (response && response.errorMessage) {
          // display message
          showToasterMessage(response.errorMessage, 'error');
        }
        setIsLoading(false);
        this.clearInputFields();
      }).catch((err) => {
        return err;
      });
  }

 
  // clear input data after submit password changes
  const clearInputFields = () => {
    reset({
      currentPassword:'',
      newPassword:'',
      confirmPassword:'',
    });
   
  }

  const goBack = () => {
    navigate(-1)
  }
 

    return (
      <div className="col-md-8" >
        <Loader loader={isLoading} />
        <Card>
          <CardHeader style={{ fontSize: 20 }}>Change Password</CardHeader>
          <CardBody>
            <form className='form' onSubmit={handleSubmit(submit)} >
              <div className='row row-cols-12'>                  
                   
                    <Controller
                      name="currentPassword"
                      control={control}
                      render={({ field,fieldState }) => (
                        <DoPasswordField
                        input={field}
                        id={field.name ? field.name : null}
                        name={field.name}
                        field={field}
                        fieldState={fieldState}
                        errors={errors}
                        placeholder={'Current Password'}
                      />
                      )}
                     
                    />                 
                    <Controller
                      name="newPassword"
                      control={control}
                      render={({ field,fieldState }) => (
                        <DoPasswordField
                        input={field}
                        id={field.name ? field.name : null}
                        name={field.name}
                        field={field}
                        fieldState={fieldState}
                        errors={errors}
                        placeholder={'New Password'}
                      />
                      )}                      
                    />
                    <Controller
                      name="confirmPassword"
                      control={control}
                      render={({ field ,fieldState}) => (
                        <DoPasswordField
                        input={field}
                        id={field.name ? field.name : null}
                        name={field.name}
                        field={field}
                        fieldState={fieldState}
                        errors={errors}
                        placeholder={'Confirm Password'}
                      />
                      )}
                      
                    />
                    
                
              </div>
              <div style={{ margin: 'auto' }}>
                <Button color='primary' type='submit'>Submit
                </Button>
                <Button color='primary' outline style={{ marginLeft: 10 }}
                  onClick={() => clearInputFields()}>Reset
                </Button>
                <Link onClick={goBack}>
                  <Button color='primary' style={{ marginLeft: 10 }}
                  >Back</Button>
                </Link>
              </div>

            </form>
          </CardBody>
        </Card>
        {redirect ? <Navigate to={config.defaultRoute} /> : null}
      </div >
    );
  }


export default ChangePasswordForm;
