import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Button} from 'reactstrap';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from 'primereact/dialog';
import { useForm , Controller} from 'react-hook-form';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import DoRadioButtons from '../../Form/Fields/DoRadioButtons';
import configMessages from '../../../config/configMessages';
import apiCalls from '../../../config/apiCalls';


const ShareChatModal = (props) => {
    const [input , setInput] = useState(props.chatLink.link);
    const [copySuccess ,setCopySuccess] = useState(false)
    const syncOptions = [{label : 'Sync',name : 'Sync' ,value : 'Sync'},{label : 'Up To Here' ,name : 'Up To Here' , value : 'Up To Here' }];
    const [errorMessage , setErrorMessage] = useState('');

    const {
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
        control,
        watch
    } = useForm({
        // resolver: yupResolver(schema),
    });
    

    const copyToClipboard = () => {
        navigator.clipboard.writeText(input).then(() => {
            setCopySuccess(true);
            setTimeout(() => {
                setCopySuccess(false)
            }, 1500);
        }, () => {
            setCopySuccess(false);
        });
    }

    const submit = (values) => {
        if(values) {
            let body = {...values, sharedChatId : props.chatLink.sharedChatId, chatId : props.chatId};
            if(errorMessage) {
                body.hisApproved = true ;
            }
            fetchMethodRequest("POST", apiCalls.shareChatEmployees, body)
                .then(async (res) => {
                    if(res?.alertMessage && res?.alertMessage === configMessages.shareChatAlertMessage) {
                        setErrorMessage(configMessages.shareWarningMessage)
                    }
                    else {
                        if(errorMessage) {
                            setErrorMessage('');
                        }
                        if (res && res.respMessage) {
                            props.closeDeleteModal()
                            showToasterMessage(res.respMessage, 'success');
                        } else {
                            props.closeDeleteModal()
                            showToasterMessage(res.errorMessage, 'error');
                        }
                    }
                })
                .catch((err) => {
                    console.error("Fetch error:", err);
                });
        }
    }

    const getFilterOptions = () => {
        if(localStorage.getItem('loginCredentials')){
            let loginId = JSON.parse(localStorage.getItem('loginCredentials'))?._id;
            if(loginId) {
                return [loginId]
            }
        }
        return []
    }

    return (
        <Dialog 
            className='screen'
            visible={props.openSharedModal}
            draggable={false}
            style={{ width: "35%" }}
            onHide={props.closeDeleteModal}
            closeOnEscape={false}
            focusOnShow={false}
            blockScroll={false}
            header={
                <div className='d-flex align-items-center'>
                    <h4>
                        Share Chat
                    </h4>

                </div>
            } >
            <ModalBody style={{ padding: '20px' }}>
            <div className="chat-bot-input-wraapper col-sm-12 px-0">
                <div className="chatbot-input col-sm-12 mt-3">
                    <input
                    type="text"
                    value={input}
                    placeholder={'Search Here ...'}
                    />
                    <button title='Copy Link' onClick={copyToClipboard}>
                    {copySuccess ?
                    <FontAwesomeIcon
                        className='genderIconAlignment'
                        color='#fff'
                        icon='check'
                        data-toggle="tool-tip"
                        title='Copy Link'
                    />
                    :<FontAwesomeIcon
                        className='genderIconAlignment'
                        color='#fff'
                        icon='clone'
                        data-toggle="tool-tip"
                        title='Copy Link'
                    />}
                    </button>
                </div>
            </div>
            {!props.chatLink?.link.endsWith('message') && <form onSubmit={handleSubmit(submit)} autoComplete="off">
                    <div style={{ width: '100%' }}>
                        <div className="form-group">
                            <Controller
                                name="sharedWith"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoAutoCompleteField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        multiple={true}
                                        fieldState={fieldState}
                                        errors={errors}
                                        screen={props.type}
                                        searchApi="employees"
                                        searchField="email"
                                        placeholder="Add People"
                                        allow={props.allowDuplicates}
                                        getValues={getValues}
                                        watch={watch}
                                        setValue={setValue}
                                        filterOptions={{key : '_id' , value : getFilterOptions , type : 'nin'}}
                                    />
                                )}
                            />
                        </div>
                        <div className='form-group'>
                            <Controller
                                    name={"syncChatType"}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoRadioButtons
                                        markReq={false}
                                        options={syncOptions}
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        label={"Sync Chat Type"}
                                        fieldState={fieldState}
                                        errors={errors}
                                        defVal={"Sync"}
                                        />)}
                                    />        
                        </div>
                    </div>
                    {<small className='text-danger'>{errorMessage}</small>}
                    <div className='modal-buttons' style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <Button color="secondary" outline onClick={props.closeDeleteModal} style={{ marginRight: '10px' }}>{errorMessage ? "No" : "Cancel"}</Button>
                        <Button color="primary" type='submit'>{errorMessage ? "Yes" : 'Send'}</Button>
                    </div>
                </form>}
            </ModalBody>
        </Dialog>
    );
};

export default ShareChatModal;
